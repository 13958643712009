import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PremiumFeatures = () => {
  return (
    <Box 
      sx={{ 
        marginTop: 2, 
        borderRadius: 3, 
        padding: 3, 
        backgroundColor: "#f9f9f9", 
        boxShadow: 2 
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1 }}>
        Premium Benefits
      </Typography>
      <List>
        {[
          "Premium features are available for all notes created or edited when Premium status is active (even after it expires).",
          "No captcha during adding notes (even after Premium expires).",
          "No ads during adding notes (even after Premium expires).",
        ].map((text, index) => (
          <ListItem key={index} sx={{ paddingY: 0.5 }}>
            <ListItemIcon>
              <CheckCircleIcon color="success" />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PremiumFeatures;
