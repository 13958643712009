import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalCheckout = ({Value}) => {
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(null);

  return (
    <PayPalScriptProvider options={{ "client-id": "Ae3QaOlByQXcVfXoC9qQXKirjnzCjfLEQrH7cWGuDqHBizx4HTHNxQx2eNh1XCy7CDD4WHfxhxpz47V8" }}>
      <div>
        {paid ? (
          <h2>Payment Successful!</h2>
        ) : (
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: Value, // Change this to your desired amount
                    },
                  },
                ],
              });
            }}
            onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                setPaid(true);
                localStorage.setItem("isPremium", true);
                alert(`Transaction completed by ${details.payer.name.given_name}`);
              });
            }}
            onError={(err) => {
              setError(err);
              console.error("PayPal Checkout Error:", err);
            }}
          />
        )}

        {error && <p style={{ color: "red" }}>Payment Error: {error.message}</p>}
      </div>
    </PayPalScriptProvider>
  );
};

export default PayPalCheckout;
