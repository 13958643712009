import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
} from "@mui/material";
import instance from "../../Axios/axiosConfig";
import PayPalCheckout from "../../header/PayPalCheckout";
import PremiumFeatures from "./PremiumFeatures";

const featureRows = [
  { feature: "No ads for you and your readers", guest: "❌", premium: "✅" },
  { feature: "No Captcha during adding notes", guest: "❌", premium: "✅" },
  { feature: "Publish Pages to Live within 2 hrs", guest: "❌", premium: "✅" },
  { feature: "Live Pages with no Ads", guest: "❌", premium: "✅" },
  { feature: "Get Custome Name of your Live Page", guest: "❌", premium: "✅" },
  {
    feature: "Get 500+ Backlinks from High DA Website",
    guest: "❌",
    premium: "✅",
  },
  { feature: "Keep your text for 1 year", guest: "❌", premium: "✅" },
  { feature: "Premium support within 1 hr", guest: "❌", premium: "✅" },
  { feature: "Access to beta functionality", guest: "❌", premium: "✅" },
];

const CloseIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    style={{ cursor: "pointer", position: "absolute", top: 16, right: 16 }}
  >
    <path
      fill="currentColor"
      d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05 7.05 5.636 12 10.586z"
    />
  </svg>
);

const PaymentButton = ({ open, handleClose }) => {
  const theme = useTheme();
  const [currency, setCurrency] = useState("INR");
  const [errorMessage, setErrorMessage] = useState("");

  const prices = {
    INR: { month: 99, year: 499 },
    USD: { month: 1, year: 5 },
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <CloseIcon onClick={handleClose} />
      <div style={{ textAlign: "center", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h3" color="secondary" sx={{ marginRight: "10px" }}>
          Choose Your Premium Plan
        </Typography>
        <Select
          value={currency}
          onChange={handleCurrencyChange}
          sx={{ height: "30px", fontSize: "14px", minWidth: "70px" }}
        >
          <MenuItem value="INR">INR</MenuItem>
          <MenuItem value="USD">USD</MenuItem>
        </Select>
      </div>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={5} sx={{ padding: 3, textAlign: "center", borderRadius: 3, background: "linear-gradient(135deg, #f3ec78, #af4261)" }}>
              <Typography variant="h6" color="white" fontWeight="bold">+1 Month</Typography>
              <Typography variant="h4" color="white" fontWeight="bold">{currency === "INR" ? "₹" : "$"} {prices[currency].month}</Typography>
              <Button variant="contained" sx={{ mt: 2, backgroundColor: "#ffffff", color: "#af4261" }}>
                <PayPalCheckout Value={1} />
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={5} sx={{ padding: 3, textAlign: "center", borderRadius: 3, background: "linear-gradient(135deg, #56ccf2, #2f80ed)" }}>
              <Typography variant="h6" color="white" fontWeight="bold">+1 Year</Typography>
              <Typography variant="h4" color="white" fontWeight="bold">{currency === "INR" ? "₹" : "$"} {prices[currency].year}</Typography>
              <Button variant="contained" sx={{ mt: 2, backgroundColor: "#ffffff", color: "#af4261" }}>
                <PayPalCheckout Value={5} /> 
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ marginTop: 4, borderRadius: 3 }}>
          <Table aria-label="premium features table">
            <TableHead sx={{ backgroundColor: theme.palette.secondary.main }}>
              <TableRow>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>Features</TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>Guest</TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>Premium</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featureRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.feature}</TableCell>
                  <TableCell>{row.guest}</TableCell>
                  <TableCell>{row.premium}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <PremiumFeatures />
      </DialogContent>
    </Dialog>
  );
};

export default PaymentButton;
