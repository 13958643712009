// src/App.js
import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import themes from "./themes/index";
import Routes from "./routes/index";
import { GoogleOAuthProvider } from "@react-oauth/google";
import NavigationScroll from "./components/NavigationScroll";
import AdTrigger from "./routes/AdTrigger";
function App() {
  const customization = useSelector((state) => state.customization);
  return (
    <GoogleOAuthProvider clientId="808228360614-2soj4jb2rfpfdnq9pmnksdpe7e634m78.apps.googleusercontent.com">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
            <AdTrigger />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
