import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Typography, TextField, Box, Button, Pagination, IconButton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import instance from "../Axios/axiosConfig";

const LivePage = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 12;
  
  const fetchPosts = async (title = "", page = 0) => {
    setIsLoading(true);
    try {
      const response = await instance.post(
        "/publish/search",
        { page, limit: itemsPerPage, title },
        { headers: { "Content-Type": "application/json" } }
      );
      
      const fetchedPosts = response.data.posts || [];
      setPosts(fetchedPosts);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts(searchTerm, currentPage - 1);
  }, [searchTerm, currentPage]);

  return (
    <Box sx={{ width: "80%", margin: "auto", mt: 4 }}>
      {/* Search Bar */}
      <TextField
        label="Search posts"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        sx={{ mb: 3 }}
      />
      
      {/* Posts List */}
      {isLoading ? (
        <Typography align="center">Loading...</Typography>
      ) : (
        <Grid container spacing={3}>
          {posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post._id}>
              <Card
                sx={{ cursor: "pointer", p: 2, "&:hover": { boxShadow: 3 } }}
                onClick={() => window.open(`https://justpasteit.in/publish/${post.uniqueId}.html`, "_blank")}
              >
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="h6">{post.title || "Untitled"}</Typography>
                    <IconButton 
                      href={`https://justpasteit.in/publish/${post.uniqueId}.html`} 
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="body2">
                    Visits: {post.visits || 0} | Likes: {post.likes || 0} | Dislikes: {post.dislikes || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      
      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Pagination
          size="small"
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          variant="outlined"
          color="secondary"
          shape="rounded"
          siblingCount={0}
        />
      </Box>
    </Box>
  );
};

export default LivePage;
